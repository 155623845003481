.contact {
    width: 100%;
    height: 100vh;
    position: relative;
    background: rgba(0,0,0,.5); /* Ustawienie koloru tła na czarny */
    margin: 0;
    padding: 0;
}

.contact:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.success-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: gray;
}

.success-message {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.contact.success {
    background-color: rgb(0, 0, 0); /* Kolor tła dla całej strony po wysłaniu wiadomości */
}

.circle {
    margin-bottom: 10px;
}

.center {
    text-align: center;
}

.contact .container .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #f8f8f8;
}

.contact form {
    width: 50%;
    margin: 1rem;
}

.contact .form-container div {
    display: flex;
    flex-direction: column;
}

.contact form label {
    margin: 2rem 0 .7rem 0;
    display: none;
}

.contact form input, textarea {
    padding: .5rem;
    font-size: 1.2rem;
    color: #f8f8f8;
    margin: 1rem 0;
    font-family: 'Roboto', sans-serif;
    background: rgba(0, 0, 0, .15);
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .15);
}

.contact form input:focus,textarea:focus {
    outline: var(--primary-color);
    box-shadow: 0 0 12px 0 var(--primary-color);
}

@media screen and (max-width: 940px) {
    .contact form {
        width: 100%;
    }
}